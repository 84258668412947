.header{

    //background: linear-gradient(90deg, rgba(216,217,221,1) 0%, rgba(243,243,244,1) 15%, rgba(243,243,244,1) 45%, rgba(216,217,221,1) 100%);
    //background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(203,205,210,1) 100%);
    background: rgb(203,205,210);
    background: linear-gradient(90deg, rgba(203,205,210,1) 0%, rgba(216,217,221,1) 69%, rgba(203,205,210,1) 100%);
    //clip-path: polygon(0 0, 100% 0, 100% 93%, 0% 100%);
    margin-bottom: 25px;
    /*background: linear-gradient(170deg, $header-background 64%, #fff 30%);*/
    h2{
      font-size: 1.7rem;
      line-height: 2rem;
      font-weight: 600;
      margin-top: 1.1rem;
    }

    &-logo{

      img{
        margin-top: 15px;
        max-width: 30%;
        padding-left: 10px;
      }
    }

    &-person{
        display: flex;
        justify-content: center;
        align-items: end;
        margin-top: -70px;
        position: relative;

        &::before{
          content: "     ";
          border-left: 5px solid $main-color;
          display: block;
          position: absolute;
          height: 70%;
          left: 12.5%;
          bottom: 0;
        }

        &::after{
          content: "     ";
          border-top: 5px solid $main-color;
          display: block;
          width: 50%;
          position: absolute;
          top: 0;
          right: 14%;
        }

        &-img{
        filter: drop-shadow(-25px -25px 40px #ffffff);
        border-bottom: 5px solid $main-color;
        border-right: 5px solid $main-color;
        max-width: 80%;

        }
    }

    &-intro{
      padding-bottom: 15px;

        img{
            width: 20%;
            margin-top: 1.05rem;
        }
    }
}

.header-intro-content{

  p:last-of-type{
    text-align: center;
  }
}

.content {

  font-size: 1.5rem;
  line-height: 40px;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;

    &__container {
      font-weight: 600;
      overflow: hidden;
      height: 40px;
      position: relative;

      &::after{
        content: "     ";
        border-bottom: 5px solid $main-color;
        display: block;
        width: 65%;
        position: absolute;
        bottom: 0;
        left: 26%;
     }

      &__text {
        display: inline;
        margin: 0;
        float: left;

      }

      &__list {
        margin-top: 0;
        padding-left: 80px;
        text-align: left;
        list-style: none;
        font-weight: 900;
        color: $white-color;


        -webkit-animation-name: change;
        -webkit-animation-duration: 15s;
        -webkit-animation-iteration-count: infinite;
        animation-name: change;
        animation-duration: 15s;
        animation-iteration-count: infinite;

        &__item {
          line-height:40px;
          margin:0;
          background-color: $black-color;
          padding: 0 5px;
          text-align: center;
        }
      }
    }
  }

  @-webkit-keyframes opacity {
    0%, 100% {opacity:0;}
    50% {opacity:1;}
  }

  @-webkit-keyframes change {
    0%, 6.5%, 100% {transform:translate3d(0,0,0);}
    12.5%,19% {transform:translate3d(0,-20%,0);}
    25%,31.5% {transform:translate3d(0,-40%,0);}
    37.5%,44% {transform:translate3d(0,-60%,0);}
    50%,56.5% {transform:translate3d(0,-80%,0);}
    62.5%,69% {transform:translate3d(0,-60%,0);}
    75%,81.5% {transform:translate3d(0,-40%,0);}
    87.5%,94% {transform:translate3d(0,-20%,0);}
  }

  @-o-keyframes opacity {
    0%, 100% {opacity:0;}
    50% {opacity:1;}
  }

  @-o-keyframes change {
    0%, 6.5%, 100% {transform:translate3d(0,0,0);}
    12.5%,19% {transform:translate3d(0,-20%,0);}
    25%,31.5% {transform:translate3d(0,-40%,0);}
    37.5%,44% {transform:translate3d(0,-60%,0);}
    50%,56.5% {transform:translate3d(0,-80%,0);}
    62.5%,69% {transform:translate3d(0,-60%,0);}
    75%,81.5% {transform:translate3d(0,-40%,0);}
    87.5%,94% {transform:translate3d(0,-20%,0);}
  }

  @-moz-keyframes opacity {
    0%, 100% {opacity:0;}
    50% {opacity:1;}
  }

  @-moz-keyframes change {
    0%, 6.5%, 100% {transform:translate3d(0,0,0);}
    12.5%,19% {transform:translate3d(0,-20%,0);}
    25%,31.5% {transform:translate3d(0,-40%,0);}
    37.5%,44% {transform:translate3d(0,-60%,0);}
    50%,56.5% {transform:translate3d(0,-80%,0);}
    62.5%,69% {transform:translate3d(0,-60%,0);}
    75%,81.5% {transform:translate3d(0,-40%,0);}
    87.5%,94% {transform:translate3d(0,-20%,0);}
  }

  @keyframes opacity {
    0%, 100% {opacity:0;}
    50% {opacity:1;}
  }

  @keyframes change {
    0%, 6.5%, 100% {transform:translate3d(0,0,0);}
    12.5%,19% {transform:translate3d(0,-20%,0);}
    25%,31.5% {transform:translate3d(0,-40%,0);}
    37.5%,44% {transform:translate3d(0,-60%,0);}
    50%,56.5% {transform:translate3d(0,-80%,0);}
    62.5%,69% {transform:translate3d(0,-60%,0);}
    75%,81.5% {transform:translate3d(0,-40%,0);}
    87.5%,94% {transform:translate3d(0,-20%,0);}
  }
// X-Small devices (portrait phones, less than 576px)
// No media query for `xs` since this is the default in Bootstrap
@media (min-width: 480px) {
  .header{
    h2{
      font-size: 2.5rem;
      line-height: 2.8rem;
      margin-top: 1.3rem;
    }

    &-person{

      &::before{
        height: 80%;
        left: 12%;
      }

      &::after{
        width: 55%;
        right: 13%;
      }
    }
  }

  .content {

    font-size: 35px;
    line-height: 60px;

    &__container {
      height: 60px;

      &__list {
        padding-left: 130px;

        &__item {
          line-height:60px;
          padding: 0 20px;
        }
      }
    }
  }

}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  .header{

    &-intro{

        img{
            margin-top: 1rem;
        }
    }
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .header{

    h2{
      font-size: 3rem;
      line-height: 3.5rem;
      font-weight: bold;
    }

    &-logo{

      img{
        margin-top: 30px;
      }
    }

    &-person{
      margin-top: -90px;

      &::before{
        left: 11%;
      }

      &::after{
        right: 12%;
      }
    }

    &-intro{

        img{
          width: 17%;
        }
    }
  }

  .content {
    margin-top: 1rem;
    margin-bottom: 1.5rem;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {

  .header{
    //clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);
    padding-bottom: 0;
    position: relative;

    &::after{
      position: absolute;
      content: " ";
      background-image: url(../../dist/images/strzalka.png);
      left: 41%;
      bottom: -1.5rem;
      width: 200px;
      height: 65px;
      background-size: cover;
    }

    h2{
      line-height: 3.375rem;
    }

    &-logo{
      display: flex;
      justify-content: end;
      margin-bottom: -3.5rem;

      img{
        margin-top: 3rem;
        margin-right: 2.5rem;
        max-width: 18%;
        padding-left: 0;
      }
    }

    &-person{
        margin-top: 0;

        &::before{
          content: "     ";
          display: none;
        }

        &::after{
          content: "     ";
          display: none;
        }

        &-img{
          border-bottom: 0;
          border-right: 0;
          max-width: 100%;
          align-self: flex-end;
          }
    }

    &-intro{
      margin-left: -3rem;
      margin-bottom: 4rem;

        img{
          width: 25%;
          margin-left: -6rem;
        }

      &-content{
        margin-top: 1.5rem;
      }
    }
  }

  .header-intro-content{

    p:last-of-type{
      text-align: left;
    }
  }

  .content {
    justify-content: start;
    margin-bottom: 1.5rem;
  }
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

  .header{

    &::after{
      width: 250px;
      height: 80px;
    }

    &-intro{
      margin-left: 0rem;
      margin-bottom: 4rem;

        img{
          margin-left: -7rem;
          margin-top: .7rem;
        }

      &-content{
        margin-top: 2rem;
      }
    }
  }
  .content {
    margin-bottom: 2rem;
  }
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {}