html, body {
  margin: 0;
  font-size: 16px;
  line-height: 27px;
  font-family: $main-font;
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
}

h1,h2{
  font-size: 2.5rem;
  line-height: 3.375rem;
  margin-top: 1.3rem;
  margin-bottom: 1.3rem;
  font-weight: bold;
}
h3{
  font-size: 1.625rem;
  line-height: 1.6875rem;
  margin-top: 1.2rem;
  margin-bottom: 1rem;
  font-weight: bold;
}
h4{
  font-size: 1rem;
  line-height: 1.6875rem;
  margin-top: 1.6875rem;
  margin-bottom: 0rem;
}

strong{
  font-weight: bold;
}

a{
  color: inherit;

  &:hover{
    text-decoration: none;
    color: $main-color;
}
}