// Breakpoints

$sm-min: 768px;
$md-min: 992px;
$lg-min: 1200px;

// Colors

$main-color: #fccd06;
$secondary-color: #010000;
$secendary-background-color: #f3f6f5;
$header-background: #cbcdd2;

$white-color: #ffffff;
$black-color: #010000;

// Fonts

$main-font: 'Source Sans Pro', Helvetica, sans-serif;

// animations

$speed: 0.5s;

