html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  text-decoration: none;
}

.outline {
  position: absolute;
  clip: rect(0px 0px 0px 0px);
  *clip: rect 0 0 0 0;
}

html, body {
  margin: 0;
  font-size: 16px;
  line-height: 27px;
  font-family: "Source Sans Pro", Helvetica, sans-serif;
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
}

h1, h2 {
  font-size: 2.5rem;
  line-height: 3.375rem;
  margin-top: 1.3rem;
  margin-bottom: 1.3rem;
  font-weight: bold;
}

h3 {
  font-size: 1.625rem;
  line-height: 1.6875rem;
  margin-top: 1.2rem;
  margin-bottom: 1rem;
  font-weight: bold;
}

h4 {
  font-size: 1rem;
  line-height: 1.6875rem;
  margin-top: 1.6875rem;
  margin-bottom: 0rem;
}

strong {
  font-weight: bold;
}

a {
  color: inherit;
}

a:hover {
  text-decoration: none;
  color: #fccd06;
}

.header {
  background: #cbcdd2;
  background: linear-gradient(90deg, #cbcdd2 0%, #d8d9dd 69%, #cbcdd2 100%);
  margin-bottom: 25px;
  /*background: linear-gradient(170deg, $header-background 64%, #fff 30%);*/
}

.header h2 {
  font-size: 1.7rem;
  line-height: 2rem;
  font-weight: 600;
  margin-top: 1.1rem;
}

.header-logo img {
  margin-top: 15px;
  max-width: 30%;
  padding-left: 10px;
}

.header-person {
  display: flex;
  justify-content: center;
  align-items: end;
  margin-top: -70px;
  position: relative;
}

.header-person::before {
  content: "     ";
  border-left: 5px solid #fccd06;
  display: block;
  position: absolute;
  height: 70%;
  left: 12.5%;
  bottom: 0;
}

.header-person::after {
  content: "     ";
  border-top: 5px solid #fccd06;
  display: block;
  width: 50%;
  position: absolute;
  top: 0;
  right: 14%;
}

.header-person-img {
  filter: drop-shadow(-25px -25px 40px #ffffff);
  border-bottom: 5px solid #fccd06;
  border-right: 5px solid #fccd06;
  max-width: 80%;
}

.header-intro {
  padding-bottom: 15px;
}

.header-intro img {
  width: 20%;
  margin-top: 1.05rem;
}

.header-intro-content p:last-of-type {
  text-align: center;
}

.content {
  font-size: 1.5rem;
  line-height: 40px;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.content__container {
  font-weight: 600;
  overflow: hidden;
  height: 40px;
  position: relative;
}

.content__container::after {
  content: "     ";
  border-bottom: 5px solid #fccd06;
  display: block;
  width: 65%;
  position: absolute;
  bottom: 0;
  left: 26%;
}

.content__container__text {
  display: inline;
  margin: 0;
  float: left;
}

.content__container__list {
  margin-top: 0;
  padding-left: 80px;
  text-align: left;
  list-style: none;
  font-weight: 900;
  color: #ffffff;
  -webkit-animation-name: change;
  -webkit-animation-duration: 15s;
  -webkit-animation-iteration-count: infinite;
  animation-name: change;
  animation-duration: 15s;
  animation-iteration-count: infinite;
}

.content__container__list__item {
  line-height: 40px;
  margin: 0;
  background-color: #010000;
  padding: 0 5px;
  text-align: center;
}

@-webkit-keyframes opacity {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-webkit-keyframes change {
  0%, 6.5%, 100% {
    transform: translate3d(0, 0, 0);
  }
  12.5%, 19% {
    transform: translate3d(0, -20%, 0);
  }
  25%, 31.5% {
    transform: translate3d(0, -40%, 0);
  }
  37.5%, 44% {
    transform: translate3d(0, -60%, 0);
  }
  50%, 56.5% {
    transform: translate3d(0, -80%, 0);
  }
  62.5%, 69% {
    transform: translate3d(0, -60%, 0);
  }
  75%, 81.5% {
    transform: translate3d(0, -40%, 0);
  }
  87.5%, 94% {
    transform: translate3d(0, -20%, 0);
  }
}

@-o-keyframes opacity {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-o-keyframes change {
  0%, 6.5%, 100% {
    transform: translate3d(0, 0, 0);
  }
  12.5%, 19% {
    transform: translate3d(0, -20%, 0);
  }
  25%, 31.5% {
    transform: translate3d(0, -40%, 0);
  }
  37.5%, 44% {
    transform: translate3d(0, -60%, 0);
  }
  50%, 56.5% {
    transform: translate3d(0, -80%, 0);
  }
  62.5%, 69% {
    transform: translate3d(0, -60%, 0);
  }
  75%, 81.5% {
    transform: translate3d(0, -40%, 0);
  }
  87.5%, 94% {
    transform: translate3d(0, -20%, 0);
  }
}

@-moz-keyframes opacity {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-moz-keyframes change {
  0%, 6.5%, 100% {
    transform: translate3d(0, 0, 0);
  }
  12.5%, 19% {
    transform: translate3d(0, -20%, 0);
  }
  25%, 31.5% {
    transform: translate3d(0, -40%, 0);
  }
  37.5%, 44% {
    transform: translate3d(0, -60%, 0);
  }
  50%, 56.5% {
    transform: translate3d(0, -80%, 0);
  }
  62.5%, 69% {
    transform: translate3d(0, -60%, 0);
  }
  75%, 81.5% {
    transform: translate3d(0, -40%, 0);
  }
  87.5%, 94% {
    transform: translate3d(0, -20%, 0);
  }
}

@keyframes opacity {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@keyframes change {
  0%, 6.5%, 100% {
    transform: translate3d(0, 0, 0);
  }
  12.5%, 19% {
    transform: translate3d(0, -20%, 0);
  }
  25%, 31.5% {
    transform: translate3d(0, -40%, 0);
  }
  37.5%, 44% {
    transform: translate3d(0, -60%, 0);
  }
  50%, 56.5% {
    transform: translate3d(0, -80%, 0);
  }
  62.5%, 69% {
    transform: translate3d(0, -60%, 0);
  }
  75%, 81.5% {
    transform: translate3d(0, -40%, 0);
  }
  87.5%, 94% {
    transform: translate3d(0, -20%, 0);
  }
}

@media (min-width: 480px) {
  .header h2 {
    font-size: 2.5rem;
    line-height: 2.8rem;
    margin-top: 1.3rem;
  }
  .header-person::before {
    height: 80%;
    left: 12%;
  }
  .header-person::after {
    width: 55%;
    right: 13%;
  }
  .content {
    font-size: 35px;
    line-height: 60px;
  }
  .content__container {
    height: 60px;
  }
  .content__container__list {
    padding-left: 130px;
  }
  .content__container__list__item {
    line-height: 60px;
    padding: 0 20px;
  }
}

@media (min-width: 576px) {
  .header-intro img {
    margin-top: 1rem;
  }
}

@media (min-width: 768px) {
  .header h2 {
    font-size: 3rem;
    line-height: 3.5rem;
    font-weight: bold;
  }
  .header-logo img {
    margin-top: 30px;
  }
  .header-person {
    margin-top: -90px;
  }
  .header-person::before {
    left: 11%;
  }
  .header-person::after {
    right: 12%;
  }
  .header-intro img {
    width: 17%;
  }
  .content {
    margin-top: 1rem;
    margin-bottom: 1.5rem;
  }
}

@media (min-width: 992px) {
  .header {
    padding-bottom: 0;
    position: relative;
  }
  .header::after {
    position: absolute;
    content: " ";
    background-image: url(../../dist/images/strzalka.png);
    left: 41%;
    bottom: -1.5rem;
    width: 200px;
    height: 65px;
    background-size: cover;
  }
  .header h2 {
    line-height: 3.375rem;
  }
  .header-logo {
    display: flex;
    justify-content: end;
    margin-bottom: -3.5rem;
  }
  .header-logo img {
    margin-top: 3rem;
    margin-right: 2.5rem;
    max-width: 18%;
    padding-left: 0;
  }
  .header-person {
    margin-top: 0;
  }
  .header-person::before {
    content: "     ";
    display: none;
  }
  .header-person::after {
    content: "     ";
    display: none;
  }
  .header-person-img {
    border-bottom: 0;
    border-right: 0;
    max-width: 100%;
    align-self: flex-end;
  }
  .header-intro {
    margin-left: -3rem;
    margin-bottom: 4rem;
  }
  .header-intro img {
    width: 25%;
    margin-left: -6rem;
  }
  .header-intro-content {
    margin-top: 1.5rem;
  }
  .header-intro-content p:last-of-type {
    text-align: left;
  }
  .content {
    justify-content: start;
    margin-bottom: 1.5rem;
  }
}

@media (min-width: 1200px) {
  .header::after {
    width: 250px;
    height: 80px;
  }
  .header-intro {
    margin-left: 0rem;
    margin-bottom: 4rem;
  }
  .header-intro img {
    margin-left: -7rem;
    margin-top: .7rem;
  }
  .header-intro-content {
    margin-top: 2rem;
  }
  .content {
    margin-bottom: 2rem;
  }
}

.aboutme {
  /*background: linear-gradient(#ffffff 32%,#f3f6f5 33%,#f3f6f5 64%,#ffffff 65%);*/
}

.aboutme-text {
  text-align: center;
}

.aboutme-text h3 {
  margin-top: 40px;
  margin-bottom: 20px;
}

.aboutme-points .media-point {
  margin-bottom: 15px;
}

.aboutme-points .media-point img {
  max-width: 25%;
}

.expirience-content {
  background-color: #010000;
  padding-top: 20px;
  color: #ffffff;
  position: relative;
}

.expirience-content::after {
  content: " ";
  border-bottom: 8px solid #fccd06;
  display: block;
  width: 50%;
  position: absolute;
  bottom: -8px;
  right: 0;
}

.expirience-content img {
  width: 85%;
  margin: 0 auto;
}

.expirience-content-items-text {
  margin-top: 10px;
  text-align: center;
}

.expirience-content-items button {
  display: block;
  margin: 10px auto 0 auto;
  border-radius: 0;
  border: 0;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
  -webkit-transition: all 0.8s;
  transition: all 0.8s;
  background-color: #fccd06;
  color: #010000;
}

.expirience-content-items button:hover {
  background-color: #f3f6f5;
  color: #010000;
}

.expirience-footer {
  margin-top: 40px;
}

.expirience-footer-text p:last-of-type {
  margin-top: 20px;
}

.offer-cards .header-card {
  display: block;
  height: 70px;
  line-height: 70px;
  background: #fccd06;
  padding: 0 10px;
  text-align: center;
  text-transform: uppercase;
  font-size: 1.1rem;
  transition: .6s;
  overflow: hidden;
}

.offer-cards .header-card:focus {
  outline: 0;
}

.offer-cards .header-card:before {
  content: '';
  display: block;
  position: absolute;
  background: rgba(255, 255, 255, 0.5);
  width: 60px;
  height: 100%;
  left: 0;
  top: 0;
  opacity: .5;
  filter: blur(30px);
  transform: translateX(-100px) skewX(-15deg);
}

.offer-cards .header-card:after {
  content: '';
  display: block;
  position: absolute;
  background: rgba(255, 255, 255, 0.2);
  width: 30px;
  height: 100%;
  left: 30px;
  top: 0;
  opacity: 0;
  filter: blur(5px);
  transform: translateX(-100px) skewX(-15deg);
}

.offer-cards .header-card:hover {
  background: #010000;
  color: #ffffff;
  cursor: pointer;
}

.offer-cards .header-card:hover:before {
  transform: translateX(700px) skewX(-15deg);
  opacity: 0.6;
  transition: 1s;
}

.offer-cards .header-card:hover:after {
  transform: translateX(700px) skewX(-15deg);
  opacity: 1;
  transition: 1s;
}

.offer-card .card-body {
  text-align: center;
  border-radius: 0;
  background-color: #f3f6f5;
  border: 0;
}

.offer-card .card-body .price {
  text-align: center;
  margin: 15px auto 5px auto;
  border-bottom: 5px solid #fccd06;
}

.offer-card .card-body .price p {
  padding: 2px 0;
}

.offer-card .card-body .price span {
  background-color: #010000;
  color: #ffffff;
  font-weight: bold;
  padding: 5px 10px;
  margin-left: 5px;
}

.portfolio-boxes .box {
  width: 100%;
  height: 18rem;
  margin: 15px auto;
}

.portfolio-boxes .box .cart {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: #f3f6f5;
  display: flex;
  align-items: center;
}

.portfolio-boxes .box .cart .back {
  height: 80%;
  width: 80%;
  border: 5px solid #fccd06;
  margin: 0 auto;
  position: relative;
  border-bottom: 0;
  display: flex;
  align-items: center;
}

.portfolio-boxes .box .cart .back::after {
  content: "     ";
  border-bottom: 5px solid #fccd06;
  display: block;
  width: 55%;
  position: absolute;
  bottom: 0;
  left: 0;
}

.portfolio-boxes .box .cart .back p {
  padding: 0 2rem;
  line-height: 1.5;
}

.portfolio-boxes .box .cart .back span {
  display: block;
  position: absolute;
  bottom: 6px;
  left: 50%;
  transform: translate(-50%, 0);
  background: #010000;
  color: #ffffff;
  text-transform: uppercase;
  padding: 5px 10px;
  transition: 0.8s;
  overflow: hidden;
}

.portfolio-boxes .box .cart .back span:focus {
  outline: 0;
}

.portfolio-boxes .box .cart .back span:before {
  content: '';
  display: block;
  position: absolute;
  background: rgba(255, 255, 255, 0.5);
  width: 60px;
  height: 100%;
  left: 0;
  top: 0;
  opacity: .5;
  filter: blur(30px);
  transform: translateX(-100px) skewX(-15deg);
}

.portfolio-boxes .box .cart .back span:after {
  content: '';
  display: block;
  position: absolute;
  background: rgba(255, 255, 255, 0.2);
  width: 30px;
  height: 100%;
  left: 30px;
  top: 0;
  opacity: 0;
  filter: blur(5px);
  transform: translateX(-100px) skewX(-15deg);
}

.portfolio-boxes .box .cart .back span:hover {
  background: #fccd06;
  color: #010000;
  cursor: pointer;
}

.portfolio-boxes .box .cart .back span:hover:before {
  transform: translateX(200px) skewX(-15deg);
  opacity: 0.6;
  transition: 1s;
}

.portfolio-boxes .box .cart .back span:hover:after {
  transform: translateX(200px) skewX(-15deg);
  opacity: 1;
  transition: 1s;
}

.portfolio-boxes .box .cart .back span a:hover {
  color: #010000;
}

.contact-form {
  margin-top: 10px;
  margin-bottom: 20px;
}

.contact-form .form-control {
  border: 5px solid #fccd06;
  border-right: 0;
  border-top: 0;
  border-left: 0;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.contact-form .form-group-textarea .form-control {
  background-color: #f3f6f5;
  border: 0;
}

.contact-form .form-check {
  line-height: 1;
  font-size: 0.85rem;
}

.contact-form button {
  border-radius: 0;
  border: 0;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
  transition: all 0.8s;
  float: right;
}

.contact-form button::after {
  content: "     ";
  border-bottom: 5px solid #fccd06;
  display: block;
  width: 100%;
  position: absolute;
  bottom: -6px;
  right: 15%;
}

.contact-form button:hover {
  background: #fccd06;
  color: #010000;
  cursor: pointer;
}

.contact-form .alert {
  padding: .75rem .25rem;
  border-radius: 0;
}

.contact-form .alert .close {
  padding-left: 10px;
  padding-right: 10px;
}

.contact-form .alert .close::after {
  display: none;
}

.contact-form .alert .close:hover {
  background: #010000;
  color: #ffffff;
  cursor: pointer;
}

.contact-image {
  display: none;
}

.contact-image img {
  display: none;
}

/* cookie alert */
#cookies-message {
  color: white;
  padding: 12px 10px;
  text-align: center;
  position: fixed;
  bottom: 0px;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 100000;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
  display: none;
}

#accept-cookies-checkbox {
  background-color: #fccd06;
  color: #010000;
  transition: all 0.8s;
  padding: 2px 6px;
  display: inline-block;
  margin-left: 10px;
  text-decoration: none;
  cursor: pointer;
}

#accept-cookies-checkbox:hover {
  background-color: #010000;
  color: #ffffff;
}

@media (min-width: 480px) {
  .aboutme-points .media-point img {
    max-width: 100%;
  }
}

@media (min-width: 576px) {
  .expirience-footer-img {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 20px;
  }
  .expirience-footer-img div:first-of-type {
    border-right: 5px solid #fccd06;
  }
}

@media (min-width: 768px) {
  .expirience-content {
    padding: 0;
  }
  .expirience-content img {
    width: 100%;
    margin: 0 auto;
  }
  .expirience-content-items:first-of-type {
    margin-right: -1rem;
  }
  .expirience-content-items:last-of-type {
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-bottom: 0;
    margin-left: -1rem;
  }
  .expirience-content-items-text {
    text-align: left;
  }
  .expirience-content-items button {
    margin: 10px 0 15px 0;
  }
  .portfolio-boxes .box .cart .back p {
    padding: 0 1rem;
  }
  .contact-image {
    display: flex;
    align-items: flex-end;
    height: 351px;
    background-color: #010000;
  }
  .contact-image img {
    display: block;
    margin-top: 15px;
  }
}

@media (min-width: 992px) {
  .expirience-content-items {
    margin: 0;
  }
  .expirience-content-items:first-of-type {
    margin-right: -2rem;
  }
  .expirience-content-items:last-of-type {
    margin-left: -2rem;
  }
  .offer .card .offer-content {
    min-height: 270px;
  }
  .contact-image img {
    width: 70%;
  }
}

@media (min-width: 1200px) {
  .aboutme::after {
    top: -85px;
    left: 41%;
    width: 250px;
    height: 80px;
  }
  .offer .card .offer-content {
    min-height: 220px;
  }
  .portfolio-boxes .box .cart .back {
    width: 79%;
  }
  .portfolio-boxes .box .cart .back p {
    padding: 0 3rem;
  }
}

.footer {
  background: #010000;
  color: #ffffff;
  text-align: center;
  margin-top: 20px;
}

.footer-logo {
  margin-bottom: 15px;
}

.footer-logo-img {
  width: 36%;
  display: block;
  margin: 20px auto;
}

.footer-data {
  margin-bottom: 20px;
}

.footer-data-img {
  display: block;
  margin: 0 auto 15px auto;
}

.footer-data p {
  line-height: 2;
}

.footer-data p:first-of-type {
  margin-top: 25px;
}

.footer-data p:first-of-type::before {
  content: url(../../dist/images/mail.png);
  margin-right: 5px;
}

.footer-data p:nth-of-type(2)::before {
  content: url(../../dist/images/telefon.png);
  margin-right: 5px;
}

.footer-data p:last-of-type {
  text-transform: uppercase;
}

.footer-data-link {
  text-decoration: none;
  color: #ffffff;
}

.footer-adress {
  margin-bottom: 20px;
}

.footer-adress p {
  line-height: 2;
}

@media (min-width: 768px) {
  .footer {
    text-align: left;
  }
  .footer-logo {
    margin: 25px auto;
  }
  .footer-logo-img {
    width: 65%;
    margin: 10px 0 0 0;
  }
  .footer-data {
    margin: 25px auto;
  }
  .footer-data-img {
    display: block;
    margin: 10px 0 10px 0;
  }
  .footer-adress {
    margin: 25px auto;
  }
}
