.footer{
    background: $secondary-color;
    color: $white-color;
    text-align: center;
    margin-top: 20px;
}

.footer-logo{
    margin-bottom: 15px;

    &-img{
        width: 36%;
        display: block;
        margin: 20px auto;
    }
}

.footer-data{
    margin-bottom: 20px;

    &-img{
        display: block;
        margin: 0 auto 15px auto;

    }

    p{
        line-height: 2;
    }

    & p:first-of-type{
        margin-top: 25px;

        &::before{
            content: url(../../dist/images/mail.png);
            margin-right: 5px;
        }
    }

    & p:nth-of-type(2){

        &::before{
            content: url(../../dist/images/telefon.png);
            margin-right: 5px;
        }
    }

    & p:last-of-type{
        text-transform: uppercase;
    }


    &-link{
        text-decoration: none;
        color: $white-color;
    }
}

.footer-adress{
    margin-bottom: 20px;

    & p{
        line-height: 2;
    }

}

// X-Small devices (portrait phones, less than 576px)
// No media query for `xs` since this is the default in Bootstrap

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) { }

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {

    .footer{
        text-align: left;
    }

    .footer-logo{
        margin: 25px auto;

        &-img{
            width: 65%;
            margin: 10px 0 0 0;
        }
    }

    .footer-data{
        margin: 25px auto;

        &-img{
            display: block;
            margin: 10px 0 10px 0;
        }
    }

    .footer-adress{
        margin: 25px auto;
    }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) { }

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) { }

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {}
