.aboutme{
    /*background: linear-gradient(#ffffff 32%,#f3f6f5 33%,#f3f6f5 64%,#ffffff 65%);*/

    &-text{
        text-align: center;

        h3{
            margin-top: 40px;
            margin-bottom: 20px;
        }
    }

    &-points{

        .media-point{
            margin-bottom: 15px;

            img{
               max-width: 25%;
            }
        }
    }
}


.expirience{

    &-content{
        background-color: $secondary-color;
        padding-top: 20px;
        color: $white-color;
        position: relative;

        &::after{
            content: " ";
            border-bottom: 8px solid $main-color;
            display: block;
            width: 50%;
            position: absolute;
            bottom: -8px;
            right: 0;
        }

        img{
            width: 85%;
            margin: 0 auto;
        }

        &-items{

            &-text{
                margin-top: 10px;
                text-align: center;
            }

            button{
                display: block;
                margin: 10px auto 0 auto;
                border-radius: 0;
                border: 0;
                padding-left: 20px;
                padding-right: 20px;
                position: relative;
                -webkit-transition: all 0.8s;
                transition: all 0.8s;
                background-color: $main-color;
                color: $black-color;

                &:hover{
                    background-color: $secendary-background-color;
                    color: $black-color;
                }
            }
        }
    }

    &-footer{
        margin-top: 40px;

        &-text{

            p:last-of-type{
                margin-top: 20px;
            }
        }
    }
}

.offer{

    &-cards{

        .header-card{

            display: block;
            height: 70px;
            line-height: 70px;
            background: $main-color;
            padding: 0 10px;
            text-align: center;
            text-transform: uppercase;
            font-size: 1.1rem;
            transition: .6s;
            overflow: hidden;

            &:focus{
                outline: 0;
            }

            &:before{
                content: '';
                display: block;
                position: absolute;
                background: rgba(255,255,255,0.5);
                width: 60px;
                height: 100%;
                left: 0;
                top: 0;
                opacity: .5;
                filter: blur(30px);
                transform: translateX(-100px)  skewX(-15deg);
            }

            &:after{
                content: '';
                display: block;
                position: absolute;
                background: rgba(255,255,255,0.2);
                width: 30px;
                height: 100%;
                left: 30px;
                top: 0;
                opacity: 0;
                filter: blur(5px);
                transform: translateX(-100px) skewX(-15deg);
            }

            &:hover{
                background: $secondary-color;
                color: $white-color;
                cursor: pointer;

                &:before{
                transform: translateX(700px)  skewX(-15deg);
                opacity: 0.6;
                transition: 1s;
                }

                &:after{
                transform: translateX(700px) skewX(-15deg);
                opacity: 1;
                transition: 1s;
                }
            }

        }
    }

    &-card{

        .card-body{
            text-align: center;
            border-radius: 0;
            background-color: $secendary-background-color;
            border: 0;

            .price{
                text-align: center;
                margin: 15px auto 5px auto;
                border-bottom: 5px solid $main-color;

                p{
                    padding: 2px 0;
                }

                span{
                    background-color: $black-color;
                    color: $white-color;
                    font-weight: bold;
                    padding: 5px 10px;
                    margin-left: 5px;
                }
            }
        }
    }
}

.portfolio{

    &-boxes{

        .box{
            width:100%;
            height: 18rem;
            margin: 15px auto;

            .cart{
                position:relative;
                width:100%;
                height:100%;
                text-align:center;
                background-color: $secendary-background-color;
                display: flex;
                align-items: center;
                //margin-bottom: 0;

                .back{
                    height: 80%;
                    width: 80%;
                    border: 5px solid $main-color;
                    margin: 0 auto;
                    position: relative;
                    border-bottom: 0;
                    display: flex;
                    align-items: center;

                    &::after{
                        content: "     ";
                        border-bottom: 5px solid $main-color;
                        display: block;
                        width: 55%;
                        position: absolute;
                        bottom: 0;
                        left: 0;

                    }

                    p{
                        padding: 0 2rem;
                        line-height: 1.5;
                    }

                    span{
                        display: block;
                        position: absolute;
                        bottom: 6px;
                        left: 50%;
                        transform: translate(-50%,0);
                        background: $black-color;
                        color: $white-color;
                        text-transform: uppercase;
                        padding: 5px 10px;
                        transition: 0.8s;
                        overflow: hidden;

                        &:focus{
                            outline: 0;
                        }

                        &:before{
                            content: '';
                            display: block;
                            position: absolute;
                            background: rgba(255,255,255,0.5);
                            width: 60px;
                            height: 100%;
                            left: 0;
                            top: 0;
                            opacity: .5;
                            filter: blur(30px);
                            transform: translateX(-100px)  skewX(-15deg);
                        }

                        &:after{
                            content: '';
                            display: block;
                            position: absolute;
                            background: rgba(255,255,255,0.2);
                            width: 30px;
                            height: 100%;
                            left: 30px;
                            top: 0;
                            opacity: 0;
                            filter: blur(5px);
                            transform: translateX(-100px) skewX(-15deg);
                        }

                        &:hover{
                            background: $main-color;
                            color: $black-color;
                            cursor: pointer;

                            &:before{
                            transform: translateX(200px)  skewX(-15deg);
                            opacity: 0.6;
                            transition: 1s;
                            }

                            &:after{
                            transform: translateX(200px) skewX(-15deg);
                            opacity: 1;
                            transition: 1s;
                            }
                        }

                        a:hover{
                            color: $black-color;
                        }
                    }
                }
            }
        }
    }
}

.contact{

    &-form{
        margin-top: 10px;
        margin-bottom: 20px;

        & .form-control{
            border: 5px solid $main-color;
            border-right: 0;
            border-top: 0;
            border-left: 0;
            border-radius: 0;
            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        }

        & .form-group-textarea{

            & .form-control{
                background-color: $secendary-background-color;
                border: 0;
            }
        }

        & .form-check{
            line-height: 1;
            font-size: 0.85rem;
        }

        button{
            border-radius: 0;
            border: 0;
            padding-left: 20px;
            padding-right: 20px;
            position: relative;
            transition: all 0.8s;
            float: right;

            &::after{
                content: "     ";
                border-bottom: 5px solid $main-color;
                display: block;
                width: 100%;
                position: absolute;
                bottom: -6px;
                right: 15%;
            }

            &:hover{
				background: $main-color;
                color: $black-color;
                cursor: pointer;
            }
        }

		.alert{
            padding: .75rem .25rem;
            border-radius: 0;

                .close{
                    padding-left: 10px;
                    padding-right: 10px;

                    &::after{
                        display: none;
                    }

                    &:hover{
                        background: $black-color;
                        color: $white-color;
                        cursor: pointer;
                    }

                }
        }
    }

    &-image{
        display: none;

        img{
            display: none;
        }
    }
}

/* cookie alert */
#cookies-message{
    color: white;
    padding: 12px 10px;
    text-align: center;
    position: fixed;
    bottom:0px;
    left:0;
    right:0;
    background-color: rgba(0,0,0,0.6);
    z-index: 100000;
    box-shadow: 0 0 5px rgba(0,0,0,0.4);
    display: none;
}
#accept-cookies-checkbox{
    background-color: $main-color;
    color: $black-color;
    transition: all 0.8s;
    padding: 2px 6px;
    display: inline-block;
    margin-left: 10px;
    text-decoration: none;
    cursor: pointer;

    &:hover{
        background-color: $secondary-color;
        color: $white-color;
    }
}


@media (min-width: 480px) {
    .aboutme{
        &-points{
            .media-point{
                img{
                    max-width: 100%;
                }
            }
        }
    }
}

@media (min-width: 576px) {
    .expirience{

        &-footer{

            &-img{
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 40px;
                margin-bottom: 20px;


                div:first-of-type{
                    border-right: 5px solid $main-color;
                }
            }
        }
    }
 }

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
    .expirience{

        &-content{
            padding: 0;

            img{
                width: 100%;
                margin: 0 auto;
            }

            &-items{

                &:first-of-type{
                    margin-right: -1rem;
                }

                &:last-of-type{
                    display: flex;
                    flex-direction: column;
                    align-items: start;
                    margin-bottom: 0;
                    margin-left: -1rem;
                }

                &-text{
                    text-align: left;
                }

                button{
                    margin: 10px 0 15px 0;
                }
            }
        }
    }

    .portfolio{

        &-boxes{

            .box{

                .cart{

                    .back{

                        p{
                            padding: 0 1rem;
                        }
                    }
                }
            }
        }
    }

    .contact{

        &-image{
            display: flex;
            align-items: flex-end;
            height: 351px;
            background-color: $secondary-color;

            img{
                display: block;
                margin-top: 15px;
            }
        }
    }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {

    .expirience{

        &-content{

            &-items{
                margin: 0;

                &:first-of-type{
                    margin-right: -2rem;
                }

                &:last-of-type{
                    margin-left: -2rem;
                }
            }
        }
    }

    .offer{

        .card{

            .offer-content{
                min-height: 270px;
            }
        }
    }


    .contact{

        &-image{

            img{
                width: 70%;
            }
        }
    }
 }

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
    .aboutme{

         &::after{
            top: -85px;
            left: 41%;
            width: 250px;
            height: 80px;
        }
    }

    .offer{

        .card{

            .offer-content{
                min-height: 220px;
            }
        }
    }

    .portfolio{

        &-boxes{

            .box{

                .cart{

                    .back{
                        width: 79%;

                        p{
                            padding: 0 3rem;
                        }
                    }
                }
            }
        }
    }
 }

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {}
